import { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";

export function DatePickerUI({
  startDate,
  endDate,
  setDate,
  asSingle,
  clearable = false,
}: {
  startDate: Date;
  endDate: Date;
  setDate: (from: any, to: any) => void;
  asSingle?: boolean;
  clearable?: boolean;
}) {
  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      let from = new Date(newValue?.startDate).getTime();
      let to = new Date(newValue?.endDate).getTime();
      setDate(from, to);
    } else {
      if (clearable) {
        setDate(undefined, undefined);
      } else {
        setDate(Date.now() - 1000 * 60 * 60 * 24 * 30, new Date().getTime());
      }
    }
  };

  return (
    <Datepicker
      asSingle={asSingle}
      useRange={!asSingle}
      value={startDate && endDate ? { startDate, endDate } : null}
      onChange={handleValueChange}
      showShortcuts={true}
      readOnly={!clearable}
      containerClassName={(e) => {
        return " flex w-full text-gray-700 datePicker-container";
      }}
      inputClassName={(e) => {
        return " w-full disabled:opacity-40 disabled:cursor-not-allowed max-w-xs rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
      }}
      toggleClassName={(e) => {
        return e + " !static py-1.5 -ml-[2.5rem]";
      }}
    />
  );
}
