export const MTIMap: Record<number, { type: string; name: string; icon: any }> =
  {
    12: {
      type: 'Financial',
      name: 'Transaction',
      icon: 'bank-card-fill',
    },
    13: {
      type: 'File',
      name: 'Download',
      icon: 'file-download-fill',
    },
    14: {
      type: 'Financial',
      name: 'Reversal',
      icon: 'refund-fill',
    },
    15: {
      type: 'Financial',
      name: 'Reconciliation',
      icon: 'scales-3-fill',
    },
    16: {
      type: 'Administrative',
      name: 'Notification',
      icon: 'alarm-warning-fill',
    },
    18: {
      type: 'Terminal',
      name: 'Registration',
      icon: 'tablet-fill',
    },
  };
