import { action_code_messages } from "../utils/action_code";
import { banks } from "../utils/banks";
import {
  TransactionAmount,
  TransactionStatus,
  TransactionType,
} from "../elements/transactions/elements";
import { ViewModel, ViewModelFilter } from "./base";
import {
  BankNameElement,
  CardSchemeElement,
  DateElement,
} from "../elements/elements";
export function TransactionQuery(): ViewModelFilter[] {
  return [
    {
      name: "Date",
      key: "date",
      type: "date_range",
      selected: [],
    },
    {
      name: "Type",
      key: "transaction_type",
      type: "options",
      options: [
        { name: "refund", value: "20" },
        { name: "purchase", value: "00" },
      ],
      selected: [],
    },
    {
      name: "Action Code",
      key: "action_code",
      type: "options",
      options: Object.keys(action_code_messages).map((key) => {
        return {
          name: `[${key}] ${action_code_messages[key].message.substring(
            0,
            20
          )}`,
          value: key,
        };
      }),
      selected: [],
    },

    {
      name: "STAN",
      key: "system_trace_audit_number",
      type: "search",
      selected: [],
      pattern: "[0-9]{6}",
      maxLength: 6,
    },
    {
      name: "RRN",
      key: "retrival_reference_number",
      type: "search",
      selected: [],
      pattern: "[0-9]{12}",
      maxLength: 12,
    },
    {
      name: "Bank",
      key: "acquirer_institution_identification_code",
      type: "options",
      options: Object.keys(banks).map((key: string) => {
        return {
          // todo -- language
          name: banks[Number(key)].name.english,
          value: key,
        };
      }),
      pattern: "[0-9]{6}",
      maxLength: 6,
      selected: [],
    },
    // {
    //   name: "Amount",
    //   key: "amount_authorized",
    //   type: "search",
    //   selected: [],
    // },
    // {
    //   name: "TID",
    //   key: "terminal",
    //   type: "search",
    //   selected: [],
    //   pattern: "[0-9]{16}",
    //   maxLength: 16,
    // },
  ];
}
export function transactionModel(i: any): ViewModel {
  return getTransactionModel(i) as ViewModel;
}

const getTransactionModel = (i: any) => {
  switch (process.env.REACT_APP_DASHBOARD_TYPE) {
    case "accounts":
      return getAccountsTransactionModel(i);
    case "nsp":
      return getNspTransactionModel(i);
    case "merchants":
      return getMerchantsTransactionModel(i);
  }
};

// will be moved to separate file
const getAccountsTransactionModel = (i: any) => {
  return {
    list: {
      Type: TransactionType(i.transaction_type, i.is_reversed),
      Amount: TransactionAmount(
        Number(Number(Number(i.amount_authorized) / 100).toFixed(2))
      ),
      Bank: BankNameElement(i.acquirer_institution_identification_code),
      Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
    },
    details: {
      Type: TransactionType(i.transaction_type, i.is_reversed),
      Amount: TransactionAmount(
        Number(Number(Number(i.amount_authorized) / 100).toFixed(2))
      ),
      Card: CardSchemeElement(i.card_scheme, i.pan),
      RRN: (
        <code className="font-semibold">
          <p>{i.retrival_reference_number}</p>
        </code>
      ),
      STAN: (
        <code className="font-semibold">
          <p>{i.system_trace_audit_number}</p>
        </code>
      ),

      Bank: BankNameElement(i.acquirer_institution_identification_code, {
        fullname: true,
      }),
      Date: <DateElement date={i.created_at} />,
    },
    relations: i.messages?.map?.((item: any) => ({
      MTI: item.mti,
      ...(item.action_code
        ? {
            "Action Code":
              action_code_messages[item.action_code].message +
              ` (${item.action_code})`,
          }
        : {}),
      STAN: item.system_trace_audit_number,
      RRN: item.retrival_reference_number,
      Bank: (
        <p className="flex items-center">
          ({banks[i.acquirer_institution_identification_code]?.code}){" "}
          {banks[i.acquirer_institution_identification_code]?.name?.english}{" "}
        </p>
      ),
      Date: <DateElement date={i.created_at} />,
    })),
    raw: i,
  };
};

const getMerchantsTransactionModel = (i: any) => {
  return {
    list: {
      Type: TransactionType(i.transaction_type, i.is_reversed),
      Amount: TransactionAmount(
        Number(Number(Number(i.amount_authorized) / 100).toFixed(2))
      ),
      Bank: BankNameElement(i.acquirer_institution_identification_code),
      Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
    },
    details: {
      Type: TransactionType(i.transaction_type, i.is_reversed),
      Amount: TransactionAmount(
        Number(Number(Number(i.amount_authorized) / 100).toFixed(2))
      ),
      Card: CardSchemeElement(i.card_scheme, i.pan),
      RRN: (
        <code className="font-semibold">
          <p>{i.retrival_reference_number}</p>
        </code>
      ),
      STAN: (
        <code className="font-semibold">
          <p>{i.system_trace_audit_number}</p>
        </code>
      ),

      Bank: BankNameElement(i.acquirer_institution_identification_code, {
        fullname: true,
      }),
      Date: <DateElement date={i.created_at} />,
    },
    relations: i.messages?.map?.((item: any) => ({
      MTI: item.mti,
      ...(item.action_code
        ? {
            "Action Code":
              action_code_messages[item.action_code].message +
              ` (${item.action_code})`,
          }
        : {}),
      STAN: item.system_trace_audit_number,
      RRN: item.retrival_reference_number,
      Bank: (
        <p className="flex items-center">
          ({banks[i.acquirer_institution_identification_code]?.code}){" "}
          {banks[i.acquirer_institution_identification_code]?.name?.english}{" "}
        </p>
      ),
      Date: <DateElement date={i.created_at} />,
    })),
    raw: i,
  };
};
const getNspTransactionModel = (i: any) => {
  return {
    list: {
      Type: TransactionType(i.transaction_type, i.is_reversed),
      Amount: TransactionAmount(
        Number(Number(Number(i.amount_authorized) / 100).toFixed(2))
      ),
      Status: TransactionStatus(i.action_code),
      Card: CardSchemeElement(i.card_scheme, i.pan),
      "RRN & STAN": (
        <code className="font-semibold">
          <p>{i.retrival_reference_number}</p>
          <small>{i.system_trace_audit_number}</small>
        </code>
      ),
      Terminal: (
        <code className="font-semibold">
          {i.terminal?.card_acceptor_terminal_id}
        </code>
      ),
      "Retiler ID": (
        <code className="font-semibold">
          {i.terminal?.card_acceptor_id.trim()}
        </code>
      ),
      Bank: BankNameElement(i.acquirer_institution_identification_code),
      Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
    },
    details: {
      Type: TransactionType(i.transaction_type, i.is_reversed),
      Amount: TransactionAmount(
        Number(Number(Number(i.amount_authorized) / 100).toFixed(2))
      ),
      Status: TransactionStatus(i.action_code),
      Card: CardSchemeElement(i.card_scheme, i.pan),
      RRN: (
        <code className="font-semibold">
          <p>{i.retrival_reference_number}</p>
        </code>
      ),
      STAN: (
        <code className="font-semibold">
          <p>{i.system_trace_audit_number}</p>
        </code>
      ),
      Terminal: (
        <code className="font-semibold">
          {i.terminal?.card_acceptor_terminal_id}
        </code>
      ),
      "Retiler ID": (
        <code className="font-semibold">
          {i.terminal?.card_acceptor_id.trim()}
        </code>
      ),
      Bank: BankNameElement(i.acquirer_institution_identification_code, {
        fullname: true,
      }),
      Date: <DateElement date={i.created_at} />,
    },
    relations: i.messages?.map((item: any) => ({
      MTI: item.mti,
      ...(item.action_code
        ? {
            "Action Code":
              action_code_messages[item.action_code].message +
              ` (${item.action_code})`,
          }
        : {}),
      STAN: item.system_trace_audit_number,
      RRN: item.retrival_reference_number,
      Bank: (
        <p className="flex items-center">
          ({banks[i.acquirer_institution_identification_code]?.code}){" "}
          {banks[i.acquirer_institution_identification_code]?.name?.english}{" "}
        </p>
      ),
      Date: <DateElement date={i.created_at} />,
    })),
    raw: i,
  };
};
