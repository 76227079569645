import Table from "../../table";
import { NearPaySession } from "../../api/core";
import { useState } from "react";
import SlideOverUI from "../../ui/slide-overs";
import CardDetilsUI from "../../ui/card-details";
import { ViewModel } from "../../models/base";

export default function AccountsTerminalsPage({
  session,
}: {
  session: NearPaySession;
}) {
  const [select, setSelect] = useState<any>();

  return (
    <>
      <SlideOverUI
        title="Terminals Details"
        isOpen={select ? true : false}
        close={() => setSelect(undefined)}
      >
        {select && <CardDetilsUI title="Terminal" data={select.details} />}
      </SlideOverUI>

      <Table
        session={session}
        listName={"terminals"}
        title="Terminals"
        onClick={(item: ViewModel) => setSelect(item)}
      />
      <br />
    </>
  );
}
