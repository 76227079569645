import { action_code_messages } from '../../utils/action_code';
import { MTIMap } from '../../utils/mti';

export const MessagesStatus = (code: string) => {
  let state = action_code_messages[code];

  return (
    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
      {state?.approved ? (
        <div className="flex-none rounded-full p-1 text-green-400 bg-green-400/10">
          <div className="h-3 w-3 rounded-full bg-current"></div>
        </div>
      ) : (
        <div className="flex-none rounded-full p-1 text-red-400 bg-red-400/10">
          <div className="h-3 w-3 rounded-full bg-current"></div>
        </div>
      )}
      <div className="whitespace-pre-wrap">
        {state.message.toLocaleUpperCase()}
        <small> {!state?.approved && `(${state.code})`}</small>
      </div>
    </div>
  );
};

export const MTIElement = (code: string) => {
  let mti = MTIMap[Number(code.substring(0, 2))];
  if (!mti)
    return <code className="font-semibold">{code.toLocaleUpperCase()}</code>;
  return (
    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
      <code className="font-semibold">{code.toLocaleUpperCase()}</code>
      <p>
        [ {mti.type} ] {mti.name}
      </p>
    </div>
  );
};
