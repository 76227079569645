import { useState } from "react";
import { NearPaySession } from "../../api/core";
import CardDetilsUI from "../../ui/card-details";
import SlideOverUI from "../../ui/slide-overs";
import Table from "../../table";

export default function AccountsWebhooksPage({
  session,
}: {
  session: NearPaySession;
}) {
  const [select, setSelect] = useState<any>();
  return (
    <>
      <SlideOverUI
        title="Webhooks Details"
        isOpen={select ? true : false}
        close={() => setSelect(undefined)}
      >
        {select && <CardDetilsUI title="Webhooks" data={select.details} />}
        <br />
      </SlideOverUI>
      <Table
        actionButton={<div>Hi i'm button</div>}
        session={session}
        listName={"webhook"}
        title="Webhooks"
        onClick={(item: any) => setSelect(item)}
      />
      <br />
    </>
  );
}
