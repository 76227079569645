import { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/login";
import ErrorPage from "./ui/errorpage";
import { NearPayAuth, NearPaySession } from "./api/core";
import { Loading } from "./ui/loading";
import { RouterGroupsLinks, RoutesPages } from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const ProtectedRoute = ({
  session,
  children,
}: {
  session: NearPaySession | undefined;
  children: any;
}) => {
  if (!session) return <Navigate to="/login" replace />;
  let user = session.info()?.data?.user;

  // if (!user?.data.clients.find((i) => i.is_nsp))
  //   <Navigate to="/login" replace />;
  if (user) return children;
};

const PublicRoute = ({
  session,
  children,
}: {
  session: NearPaySession | undefined;
  children: any;
}) => {
  if (session) return <Navigate to="/" replace />;
  return children;
};

function MainApp() {
  let auth = new NearPayAuth();
  let [session, setSession] = useState<NearPaySession | undefined>();
  let [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    auth
      .getSession()
      .then((current_session) => {
        if (current_session !== null) setSession(current_session);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getDashboardType = () => {
    switch (process.env.REACT_APP_DASHBOARD_TYPE) {
      case "accounts":
        return "accounts";
      case "merchants":
        return "merchants";
      case "nsp":
        return "nsp";
      default:
        return "accounts";
    }
  };

  const getRoutes = () => {
    const regex = new RegExp(`${getDashboardType()}\/*`);
    if (!regex.test(window.location.pathname)) {
      window.location.replace(getDashboardType() + (session ? "" : "/login"));
    }
    return (
      <BrowserRouter basename={getDashboardType()}>
        <Routes>
          <Route
            path="/"
            element={
              session ? (
                <App
                  session={session}
                  groups={RouterGroupsLinks[getDashboardType()]}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          >
            {RoutesPages[getDashboardType()].map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <ProtectedRoute session={session}>
                      {session && <route.component session={session} />}
                    </ProtectedRoute>
                  }
                />
              );
            })}

            <Route path="*" element={<ErrorPage />} />
          </Route>

          <Route
            path="/login"
            element={
              <PublicRoute session={session}>
                <LoginPage setSession={setSession} auth={auth} />
              </PublicRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  };
  if (loading) return <Loading />;
  return getRoutes();
}
root.render(<MainApp />);
reportWebVitals();
