import ANBBLogo from "./logos/banks/588848.jpeg";
import ALBILogo from "./logos/banks/636120.png";
import BSFBLogo from "./logos/banks/588845.jpeg";
import NCBBLogo from "./logos/banks/588850.png";
import RAJBLogo from "./logos/banks/588847.png";
import JAZBLogo from "./logos/banks/504300.png";
import SABBLogo from "./logos/banks/588851.png";

export const banks: Record<
  number,
  {
    code: string;
    name: { arabic: string; english: string };
    logo?: JSX.Element;
  }
> = {
  636120: {
    code: "ALBI",
    name: {
      arabic: "بنك البلاد",
      english: "Al Bilad Bank",
    },
    logo: <img className="mix-blend-multiply" src={ALBILogo} height={"100%"} />,
  },
  588848: {
    code: "ANBB",
    name: {
      arabic: "البنك العربي",
      english: "Arab National Bank",
    },
    logo: (
      <img
        className="mix-blend-multiply"
        src={ANBBLogo}
        width={30}
        height={15}
      />
    ),
  },
  588845: {
    code: "BSFB",
    name: {
      arabic: "البنك السعودي الفرنسي",
      english: "Al Bank Al Saudi Al Fransi",
    },
    logo: <img className="mix-blend-multiply" src={BSFBLogo} />,
  },
  588846: {
    code: "BSHB",
    name: {
      arabic: "البنك السعودي الهولندي (الاول)",
      english: "Saudi Holandi Bank (AWAL)",
    },
    logo: <></>,
  },
  42065: {
    code: "EBIL",
    name: {
      arabic: "بنك الامارات الدولي",
      english: "Emirates Bank International Limited",
    },
    logo: <></>,
  },
  530060: {
    code: "FABM",
    name: {
      arabic: "بنك ابوظبي الاول",
      english: "First Abu Dhabi Bank",
    },
    logo: <></>,
  },
  682002: {
    code: "GEID",
    name: {
      arabic: "جيديا",
      english: "Geidea",
    },
    logo: <></>,
  },
  439954: {
    code: "GIBB",
    name: {
      arabic: "بنك الخليج الدولي",
      english: "Gulf International Bank",
    },
    logo: <></>,
  },
  432328: {
    code: "INMA",
    name: {
      arabic: "بنك الانماء",
      english: "Al Inma Bank",
    },
    logo: <></>,
  },
  504300: {
    code: "JAZB",
    name: {
      arabic: "بنك الجزيرة",
      english: "Bank Al Jazira",
    },
    logo: (
      <>
        <img className="mix-blend-multiply" src={JAZBLogo} />
      </>
    ),
  },
  604906: {
    code: "NBOB",
    name: {
      arabic: "بنك البحرين الوطني",
      english: "National Bank of Bahrain",
    },
    logo: <></>,
  },
  431361: {
    code: "NBOK",
    name: {
      arabic: "بنك الكويت الوطني",
      english: "National Bank of Kuwait",
    },
    logo: <></>,
  },
  588850: {
    code: "NCBB",
    name: {
      arabic: "البنك الاهلي التجاري",
      english: "National Commercial Bank",
    },
    logo: (
      <>
        <img className="mix-blend-multiply" src={NCBBLogo} />
      </>
    ),
  },
  588847: {
    code: "RAJB",
    name: {
      arabic: "بنك الراجحي",
      english: "Al Rajhi Banking and Investment Corporation",
    },
    logo: <img className="mix-blend-multiply" src={RAJBLogo} />,
  },
  588849: {
    code: "RYDB",
    name: {
      arabic: "بنك الرياض",
      english: "Riyadh Bank",
    },
    logo: <></>,
  },
  588851: {
    code: "SABB",
    name: {
      arabic: "ساب",
      english: "The Saudi British Bank",
    },
    logo: <img className="mix-blend-multiply" src={SABBLogo} />,
  },
  589206: {
    code: "SAIB",
    name: {
      arabic: "بنك الاستثمار السعودي",
      english: "Saudi Investment Bank",
    },
    logo: <></>,
  },
  123456789: {
    code: "SAMA",
    name: {
      arabic: "سماما",
      english: "Saudi Arabian Monetary Authority",
    },
    logo: <></>,
  },
  585265: {
    code: "SAMB",
    name: {
      arabic: "سامبا",
      english: "SAMBA Financial Group",
    },
    logo: <></>,
  },
  420132: {
    code: "STCP",
    name: {
      arabic: "اس تي سي باي",
      english: "STCPay",
    },
    logo: <></>,
  },
};
