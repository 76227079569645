import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from '@heroicons/react/20/solid';
import { action_code_messages } from '../../utils/action_code';

export const TransactionStatus = (code: string) => {
  let state = action_code_messages[code];
  if (!state) return <p>{code}</p>;
  return (
    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
      {state?.approved ? (
        <div className="flex-none rounded-full p-1 text-green-400 bg-green-400/10">
          <div className="h-3 w-3 rounded-full bg-current"></div>
        </div>
      ) : (
        <div className="flex-none rounded-full p-1 text-red-400 bg-red-400/10">
          <div className="h-3 w-3 rounded-full bg-current"></div>
        </div>
      )}
      <div className="whitespace-pre-wrap">
        {state?.message?.toLocaleUpperCase()}
        <small> {!state?.approved && `(${state.code})`}</small>
      </div>
    </div>
  );
};

export const TransactionType = (type: string, is_reversed: boolean) => (
  <div>
    <p className="flex">
      <ArrowUpCircleIcon
        className=" h-6 w-5 flex-none text-indigo-600  mr-2"
        aria-hidden="true"
      />
      {type === '00' ? 'Purchase' : 'Refund'}
    </p>
    <p className="flex">
      {is_reversed && (
        <ArrowDownCircleIcon
          className=" h-6 w-5 flex-none text-red-600  mr-2"
          aria-hidden="true"
        />
      )}
      {is_reversed && 'Reversed'}
    </p>
  </div>
);

export const TransactionAmount = (amount: number) => (
  <>
    <small>SAR </small>
    <b>{amount.toFixed(2)?.toLocaleString()}</b>
  </>
);
