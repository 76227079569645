export const action_code_messages: Record<string, { code: string; message: string; approved: boolean }> = {
  "000": {
    code: "000",
    message: "Approved",
    approved: true,
  },
  "001": {
    code: "001",
    message: "Honour with identification",
    approved: true,
  },
  "003": {
    code: "003",
    message: "Approved (VIP)",
    approved: true,
  },
  "007": {
    code: "007",
    message: "Approved, update ICC",
    approved: true,
  },
  "060": {
    code: "060",
    message: "Approved in Stand-In Processing (STIP) mode by mada switch",
    approved: true,
  },
  "061": {
    code: "060",
    message: "Approved in Stand-In Processing (STIP) mode by mada POS",
    approved: true,
  },
  "087": {
    code: "087",
    message: "Offline Approved (Chip only)",
    approved: true,
  },
  "089": {
    code: "089",
    message: "Unable to go On-line. Off-line approved (Chip only)",
    approved: true,
  },
  100: {
    code: "100",
    message: "Do not honour",
    approved: false,
  },
  101: {
    code: "101",
    message: "Expired card ",
    approved: false,
  },
  102: {
    code: "102",
    message: "Suspected fraud (To be used when ARQC validation fails) ",
    approved: false,
  },
  103: {
    code: "103",
    message: "Card acceptor contact acquirer ",
    approved: false,
  },
  104: {
    code: "104",
    message: "Restricted card  ",
    approved: false,
  },
  105: {
    code: "105",
    message: "Card acceptor call acquirer’s security department",
    approved: false,
  },
  106: {
    code: "106",
    message: "Allowable PIN tries exceeded ",
    approved: false,
  },
  107: {
    code: "107",
    message: "Refer to card issuer",
    approved: false,
  },
  108: {
    code: "108",
    message: "Refer to card issuer’s special conditions",
    approved: false,
  },
  109: {
    code: "109",
    message: "Invalid merchant ",
    approved: false,
  },
  110: {
    code: "110",
    message: "The specified amount is invalid or exceeds the maximum unitary amount for the given mada transaction service",
    approved: false,
  },
  111: {
    code: "111",
    message: "Invalid card number ",
    approved: false,
  },
  112: {
    code: "112",
    message: "PIN data required",
    approved: false,
  },
  114: {
    code: "114",
    message: "No account of type requested ",
    approved: false,
  },
  115: {
    code: "115",
    message: "Requested function not supported",
    approved: false,
  },
  116: {
    code: "116",
    message: "Not sufficient funds ",
    approved: false,
  },
  117: {
    code: "117",
    message: "Incorrect PIN",
    approved: false,
  },
  118: {
    code: "118",
    message: "No card record ",
    approved: false,
  },
  119: {
    code: "119",
    message: "Transaction not permitted to cardholder",
    approved: false,
  },
  120: {
    code: "120",
    message: "Transaction not permitted to terminal",
    approved: false,
  },
  121: {
    code: "121",
    message: "Exceeds Daily limit for the given mada transaction service",
    approved: false,
  },
  122: {
    code: "122",
    message: "Security violation",
    approved: false,
  },
  123: {
    code: "123",
    message: "Exceeds frequency limit i.e. number of transactions, for the given mada transaction service",
    approved: false,
  },
  125: {
    code: "125",
    message: "Card not effective",
    approved: false,
  },
  126: {
    code: "126",
    message: "Invalid PIN block ",
    approved: false,
  },
  127: {
    code: "127",
    message: "PIN length error",
    approved: false,
  },
  128: {
    code: "128",
    message: "PIN key synch error",
    approved: false,
  },
  129: {
    code: "129",
    message: "Suspected counterfeit card",
    approved: false,
  },
  164: {
    code: "164",
    message: "Transaction not permitted for this Merchant Category Code(MCC)",
    approved: false,
  },
  165: {
    code: "165",
    message: "Excessive pre-authorization Capture not allowed.The excessive Capture attempt violates the mada business rules in terms of the total amount being captured or the allowed transaction channels or presentment mode",
    approved: false,
  },
  166: {
    code: "166",
    message: "More than one pre-authorization extension is not allowed",
    approved: false,
  },
  167: {
    code: "167",
    message: "Issuer not certified for this transaction or service or Issuer does not support this service",
    approved: false,
  },
  182: {
    code: "182",
    message: "Invalid date (Visa 80)",
    approved: false,
  },

  183: {
    code: "183",
    message: "Cryptographic error found in PIN or CVV (Visa 81)",
    approved: false,
  },

  184: {
    code: "184",
    message: "Incorrect CVV (Visa 82)",
    approved: false,
  },

  185: {
    code: "185",
    message: "Unable to verify PIN (Visa 83)",
    approved: false,
  },

  187: {
    code: "187",
    message: "Original transaction for refund, preauthorization capture, preauthorization void or preauthorization extension not found based on original transaction data.",
    approved: false,
  },

  188: {
    code: "188",
    message: "Offline declined.",
    approved: false,
  },

  190: {
    code: "190",
    message: "Unable to go online – Offline declined",
    approved: false,
  },
  195: {
    code: "195",
    message: "Card insertion required to proceed the transaction",
    approved: false,
  },
  196: {
    code: "196",
    message: "Online PIN is required",
    approved: false,
  },
  197: {
    code: "197",
    message: "The bank account used in the original transaction does not match the bank account being used in the Refund, Preauthorization Capture, reauthorization Void or Preauthorization Extension transaction.",
    approved: false,
  },
  198: {
    code: "198",
    message: "The refund, preauthorization void amount or cumulative amount exceeds the original transaction amount.",
    approved: false,
  },
  199: {
    code: "199",
    message: "The refund, pre-authorization capture, preauthorization void or preauthorization extension transaction period exceeds the maximum time limit allowed by the mada business rules.",
    approved: false,
  },
  200: {
    code: "200",
    message: "Do not honour.",
    approved: false,
  },
  201: {
    code: "201",
    message: "Expired card.",
    approved: false,
  },
  202: {
    code: "202",
    message: "Suspected fraud (To be used when ARQC validation fails).",
    approved: false,
  },
  203: {
    code: "203",
    message: "Card acceptor contact acquirer.",
    approved: false,
  },
  204: {
    code: "204",
    message: "Restricted card.",
    approved: false,
  },
  206: {
    code: "206",
    message: "Allowable PIN tries exceeded.",
    approved: false,
  },
  207: {
    code: "207",
    message: "Special conditions.",
    approved: false,
  },
  208: {
    code: "208",
    message: "Lost card.",
    approved: false,
  },
  209: {
    code: "209",
    message: "Stolen card.",
    approved: false,
  },
  210: {
    code: "210",
    message: "Suspected counterfeit card.",
    approved: false,
  },
  300: {
    code: "300",
    message: "Successful.",
    approved: true,
  },
  301: {
    code: "301",
    message: "Not supported by receiver.",
    approved: false,
  },
  302: {
    code: "302",
    message: "Unable to locate record on file.",
    approved: false,
  },
  306: {
    code: "306",
    message: "Not successful.",
    approved: false,
  },
  307: {
    code: "307",
    message: "Format error.",
    approved: false,
  },
  400: {
    code: "400",
    message: "Accepted.",
    approved: true,
  },
  480: {
    code: "480",
    message: "Original transaction not found.",
    approved: false,
  },
  481: {
    code: "481",
    message: "Original transaction was found but declined.",
    approved: false,
  },
  500: {
    code: "500",
    message: "Reconciled, in balance.",
    approved: true,
  },
  501: {
    code: "501",
    message: "Reconciled, out of balance.",
    approved: false,
  },
  690: {
    code: "690",
    message: "Unable to parse message.",
    approved: false,
  },
  800: {
    code: "800",
    message: "Accepted.",
    approved: true,
  },
  888: {
    code: "888",
    message: "Unknown error.",
    approved: false,
  },
  893: {
    code: "893",
    message: "Signature Error.",
    approved: false,
  },
  902: {
    code: "902",
    message: "Invalid transaction.",
    approved: false,
  },
  903: {
    code: "903",
    message: "Re-enter transaction.",
    approved: false,
  },
  904: {
    code: "904",
    message: "Format error.",
    approved: false,
  },
  906: {
    code: "906",
    message: "Cutover in process.",
    approved: false,
  },
  907: {
    code: "907",
    message: "Card issuer or switch inoperative.",
    approved: false,
  },
  909: {
    code: "909",
    message: "System malfunction.",
    approved: false,
  },
  910: {
    code: "910",
    message: "Card issuer signed off.",
    approved: false,
  },
  911: {
    code: "911",
    message: "Card issuer timed out.",
    approved: false,
  },
  912: {
    code: "912",
    message: "Card issuer unavailable.",
    approved: false,
  },
  913: {
    code: "913",
    message: "Duplicate transmission.",
    approved: false,
  },
  914: {
    code: "914",
    message: "Not able to trace back to original transaction.",
    approved: false,
  },
  915: {
    code: "915",
    message: "Reconciliation cutover or checkpoint error.",
    approved: false,
  },
  916: {
    code: "916",
    message: "MAC incorrect (permissible in 1644).",
    approved: false,
  },
  917: {
    code: "917",
    message: "MAC key sync.",
    approved: false,
  },
  918: {
    code: "918",
    message: "No communication keys available for use.",
    approved: false,
  },
  919: {
    code: "919",
    message: "Encryption key sync error.",
    approved: false,
  },
  920: {
    code: "920",
    message: "Security software/hardware error – try again.",
    approved: false,
  },
  921: {
    code: "921",
    message: "Security software/hardware error – no action.",
    approved: false,
  },
  922: {
    code: "922",
    message: "Message number out of sequence.",
    approved: false,
  },
  923: {
    code: "923",
    message: "Request in progress.",
    approved: false,
  },
  940: {
    code: "940",
    message: "Unknown terminal.",
    approved: false,
  },
  942: {
    code: "942",
    message: "Invalid Reconciliation Date.",
    approved: false,
  },
  943: {
    code: "943",
    message: "Transaction service is not supported for GCCNet cards.",
    approved: false,
  },
  944: {
    code: "944",
    message: "The requested DCC Currency is invalid or no longer supported.",
    approved: false,
  },
  945: {
    code: "945",
    message: "The Acquirer ID, Merchant ID, Store ID or Department ID specified in the DCC Rate Inquiry is not enabled for the DCC service.",
    approved: false,
  },
  946: {
    code: "946",
    message: "Acquirer timed out.",
    approved: false,
  },
};
