import { action_code_messages } from "../utils/action_code";
import { ViewModel, ViewModelFilter } from "./base";
import { BankNameElement, DateElement } from "../elements/elements";
import { MTIElement, MessagesStatus } from "../elements/messages/elements";
import { TreeUI } from "../ui/treeui";

export function MessageQuery(): ViewModelFilter[] {
  return [
    {
      name: "Date",
      key: "date",
      type: "date_range",
      selected: [],
    },
    {
      name: "Message Type",
      key: "mti",
      type: "options",
      options: [
        { name: "[1200] Financial Req ", value: "1200" },
        { name: "[1210] Financial Res ", value: "1210" },
        { name: "[1420] Reversal Req ", value: "1420" },
        { name: "[1430] Reversal Res ", value: "1430" },
        { name: "[1304] File Download Req ", value: "1304" },
        { name: "[1314] File Download Res ", value: "1314" },
        { name: "[1524] Reconciliation Req ", value: "1524" },
        { name: "[1534] Reconciliation Res ", value: "1534" },
        // { name: 'Reconciliation Res ', value: '1210' },
      ],
      selected: [],
      //todo -- add pattern
    },
    {
      name: "Action Code",
      key: "action_code",
      type: "options",
      options: Object.keys(action_code_messages).map((key) => {
        return {
          name: `[${key}] ${action_code_messages[key].message.substring(
            0,
            20
          )}`,
          value: key,
        };
      }),
      selected: [],
      //todo -- add pattern
    },
    {
      name: "TID",
      key: "card_acceptor_terminal_id",
      type: "search",
      selected: [],
      pattern: "[0-9]{16}",
      maxLength: 16,
    },
    {
      name: "STAN",
      key: "system_trace_audit_number",
      type: "search",
      selected: [],
      pattern: "[0-9]{6}",
      maxLength: 6,
    },
    {
      name: "RRN",
      key: "retrival_reference_number",
      type: "search",
      selected: [],
      pattern: "[0-9]{12}",
      maxLength: 12,
    },
    // {
    //   name: "Amount",
    //   key: "transactions.amount_authorized",
    //   type: "search",
    //   selected: [],
    // },
    // {
    //   name: "Bank",
    //   key: "terminal.acquirer_institution_identification_code",
    //   type: "search",
    //   selected: [],
    // },
  ];
}

export function MessagesModel(i: any): ViewModel {
  let view = {
    MTI: MTIElement(i.mti),
    Pages: (
      <>
        {i.message.data_record && i.message.data_record.page && (
          <>
            ({i.message.data_record.page}) From ({i.message.data_record.total})
          </>
        )}
      </>
    ),
    Amount: (
      <>
        {i.message.transaction_amount && (
          <>{i.message.transaction_amount / 100}</>
        )}
      </>
    ),
    "Action Code": i.action_code && MessagesStatus(i.action_code),
    STAN: <code className="font-semibold">{i.system_trace_audit_number}</code>,
    TID: <code className="font-semibold">{i.card_acceptor_terminal_id}</code>,
    RRN: <code className="font-semibold">{i.retrival_reference_number}</code>,
    Bank: BankNameElement(i.message.acquirer_institution_identification_code),
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
      Bank: BankNameElement(
        i.message.acquirer_institution_identification_code,
        {
          fullname: true,
        }
      ),
    },
    relations: [
      Object.keys(i.message).reduce((obj: any, k) => {
        if (!obj[k.toUpperCase()])
          obj[k.toUpperCase()] =
            typeof i.message[k] === "object" ? (
              <TreeUI data={i.message[k]} />
            ) : (
              <>{i.message[k]}</>
            );
        return obj;
      }, {}),
    ],
    raw: i,
  };
}
