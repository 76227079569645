import { timeAgo } from "../pages/utils/time";
import { banks } from "../utils/banks";
import { schemes } from "../utils/schemes";

export const CardSchemeElement = (scheme: string, pan: string) => (
  <div className="flex items-center">
    {schemes[scheme] && (
      <div style={{ width: "30px" }}>{schemes[scheme].logo}</div>
    )}
    <p className="p-2">{pan}</p>
  </div>
);

export function DateElement({
  date,
  opt,
}: {
  date: string;
  opt?: { relative: boolean };
}) {
  return (
    <time dateTime={date} title={new Date(date).toLocaleString()}>
      {opt?.relative
        ? timeAgo(new Date(date).getTime())
        : new Date(date).toLocaleString()}
    </time>
  );
}

export function BankNameElement(
  bank_code: number,
  opt: { fullname: boolean } = { fullname: false }
) {
  let bank = banks[bank_code];
  if (!bank) return <>{bank_code}</>;
  return (
    <div className="flex items-center">
      <div style={{ width: "30px" }}>{bank?.logo}</div>
      {opt.fullname ? (
        <p style={{ paddingLeft: "5px" }}>
          {bank.code} ({bank.name.english})
        </p>
      ) : (
        <p style={{ paddingLeft: "5px" }}>{bank?.code}</p>
      )}
    </div>
  );
}
