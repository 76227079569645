import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../logo.svg";
import { Link, NavLink } from "react-router-dom";
import { RoutersGroups } from "../routes";
import { UserCircle2Icon } from "lucide-react";

export default function NavSideBar({
  children,
  logout,
  groups,
  profile,
}: {
  children: any;
  logout: () => void;
  groups: RoutersGroups[];
  profile: any;
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={Logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        {NavbarGroup({
                          groups,
                          onClick: () => setSidebarOpen(false),
                        })}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img className="h-6 w-auto" src={Logo} alt="Your Company" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7 cursor-pointer">
                {NavbarGroup({ groups, onClick: () => setSidebarOpen(false) })}

                <li className="-mx-6 mt-auto group">
                  <ProfileMenu logout={logout} />
                  <a
                    // href="/login"
                    // onClick={() => setShowMenu(true)}
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <div className="h-8 w-8 rounded-full bg-gray-800 flex items-center justify-center">
                      <UserCircle2Icon />
                    </div>

                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">
                      <p>
                        {profile?.user?.name
                          ? profile?.user?.name.toLocaleUpperCase()
                          : profile?.user?.name.toLocaleUpperCase()}
                      </p>
                      <small>{profile?.user?.email}</small>
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            Dashboard
          </div>
          <Link to="/login">
            <span className="sr-only">Your profile</span>
            <div className="h-8 w-8 rounded-full bg-gray-800 flex items-center justify-center text-white">
              <UserCircle2Icon />
            </div>
          </Link>
        </div>

        <main className="lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </>
  );
}

function NavbarGroup({
  groups,
  onClick,
}: {
  groups: RoutersGroups[];
  onClick: () => void;
}) {
  return groups.map((group, i) => {
    return (
      <li key={i}>
        <div className="text-xs font-semibold leading-6 text-gray-400">
          {group.title}
        </div>
        <ul className="-mx-2 space-y-1">
          {group.links.map((item) => (
            <li key={item.name}>
              <NavLink
                end
                to={item.link}
                onClick={onClick}
                className={({ isActive }: { isActive: boolean }) => {
                  let base =
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold";
                  if (isActive) return base + " bg-gray-800 text-white";
                  else
                    return (
                      base + " text-gray-400 hover:text-white hover:bg-gray-800"
                    );
                }}
              >
                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </li>
    );
  });
}

const ProfileMenu = ({ logout }: { logout: () => void }) => {
  return (
    <div className="mb-0 w-full scale-y-0 group-hover:scale-100 transition-transform origin-bottom rounded-full duration-300 ease-out p-2">
      <ul className="w-full [&_a]:cursor-pointer">
        {/* <a className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800">
          Switch language
        </a> */}
        {/* <a
          className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
          onClick={() => {}}
        >
          Switch Account
        </a>
        <a className=" flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800">
          Profile
        </a> */}
        <a
          className=" flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
          onClick={logout}
        >
          Logout
        </a>
      </ul>
    </div>
  );
};

const Accounts = () => {
  return (
    <ul className="w-full">
      <a
        className="flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
        onClick={() => {}}
      >
        hi
      </a>
      <a
        className="flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
        onClick={() => {}}
      >
        hi
      </a>
      <a
        className="flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
        onClick={() => {}}
      >
        hi
      </a>
    </ul>
  );
};
