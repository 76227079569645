import { useState } from "react";
import { NearPaySession } from "../../api/core";
import CardDetilsUI from "../../ui/card-details";
import SlideOverUI from "../../ui/slide-overs";
import Table from "../../table";

export default function NSPTransactionsPage({
  session,
}: {
  session: NearPaySession;
}) {
  const [select, setSelect] = useState<any>();
  return (
    <>
      <SlideOverUI
        title="Transactions Details"
        isOpen={select ? true : false}
        close={() => setSelect(undefined)}
      >
        {select && <CardDetilsUI title="Transaction" data={select.details} />}
        <br />
        <div className="text-1xl font-bold text-gray-600">
          Messages ({select?.relations?.length})
        </div>
        <br />
        {select &&
          select?.relations?.map((item: any, i: number) => {
            return (
              <>
                <CardDetilsUI
                  key={i}
                  title={`${item.MTI} Message`}
                  data={item}
                />
                <br />
              </>
            );
          })}
      </SlideOverUI>
      <Table
        session={session}
        listName={"transactions"}
        title="Transactions"
        onClick={(item: any) => setSelect(item)}
      />
      <br />
    </>
  );
}
