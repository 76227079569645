import { ViewModel, ViewModelFilter } from "./base";
import { BankNameElement, DateElement } from "../elements/elements";
import { banks } from "../utils/banks";

export function TerminalsModel(i: any): ViewModel {
  return getTerminalsModel(i) as ViewModel;
}

export function TerminalsQuery(): ViewModelFilter[] {
  return [
    {
      name: "Created In",
      key: "date",
      type: "date_range",
      selected: [],
    },

    {
      name: "Bank",
      key: "acquirer_institution_identification_code",
      type: "options",
      options: Object.keys(banks).map((key: string) => {
        return {
          // todo -- language
          name: banks[Number(key)].name.english,
          value: key,
        };
      }),
      pattern: "[0-9]{6}",
      maxLength: 6,
      selected: [],
    },
    {
      name: "TID",
      key: "card_acceptor_terminal_id",
      type: "search",
      pattern: "[0-9]{16}",
      maxLength: 16,
      selected: [],
    },
  ];
}

const getTerminalsModel = (i: any) => {
  switch (process.env.REACT_APP_DASHBOARD_TYPE) {
    case "accounts":
      return getAccountsTerminalModel(i);
    case "nsp":
      return getNspTerminalModel(i);
    case "merchants":
      return getMerchantsTerminalModel(i);
  }
};

const getAccountsTerminalModel = (i: any) => {
  let view = {
    TID: <code className="font-semibold">{i.card_acceptor_terminal_id}</code>,

    Bank: BankNameElement(i.acquirer_institution_identification_code),
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
      Bank: BankNameElement(i.acquirer_institution_identification_code, {
        fullname: true,
      }),
    },
    relations: [{}],
    raw: i,
  };
};
const getMerchantsTerminalModel = (i: any) => {
  let view = {
    TID: <code className="font-semibold">{i.card_acceptor_terminal_id}</code>,

    Bank: BankNameElement(i.acquirer_institution_identification_code),
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
      Bank: BankNameElement(i.acquirer_institution_identification_code, {
        fullname: true,
      }),
    },
    relations: [{}],
    raw: i,
  };
};
const getNspTerminalModel = (i: any) => {
  let view = {
    TID: <code className="font-semibold">{i.card_acceptor_terminal_id}</code>,
    TRSM: <code className="font-semibold">{i.trsm.toLocaleUpperCase()} </code>,
    "Retiler ID": (
      <code className="font-semibold">{i.card_acceptor_id.trim()}</code>
    ),
    Bank: BankNameElement(i.acquirer_institution_identification_code),
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
      Bank: BankNameElement(i.acquirer_institution_identification_code, {
        fullname: true,
      }),
    },
    relations: [{}],
    raw: i,
  };
};
