import { ViewModel, ViewModelFilter } from "./base";
import { BankNameElement, DateElement } from "../elements/elements";
import { methodElement } from "../elements/webhooks/elements";

export function WebhookModel(i: any): ViewModel {
  return getWebhookModel(i) as ViewModel;
}

export function WebhookQuery(): ViewModelFilter[] {
  return [
    {
      name: "Url",
      key: "url",
      type: "search",
      selected: [],
    },
    {
      name: "Method",
      key: "method",
      type: "options",
      options: [
        { name: "PUT", value: "put" },
        { name: "POST", value: "post" },
        { name: "DELETE", value: "Delete" },
      ],
      selected: [],
    },
  ];
}

const getWebhookModel = (i: any) => {
  switch (process.env.REACT_APP_DASHBOARD_TYPE) {
    case "accounts":
      return getAccountsWebhookModel(i);
    case "nsp":
      return getNspWebhookModel(i);
  }
};

const getAccountsWebhookModel = (i: any) => {
  let view = {
    Method: methodElement(i.method),
    event: i.event.name,
    URL: i.url,
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
const getNspWebhookModel = (i: any) => {
  let view = {
    "Webhook Name": i.name,
    "Package Name": i.package_name,
    Type: i.type,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
