import Table from "../../table";
import { NearPaySession } from "../../api/core";
import { useState } from "react";
import SlideOverUI from "../../ui/slide-overs";
import CardDetilsUI from "../../ui/card-details";
import { ViewModel } from "../../models/base";

export default function NSPMessagesPage({
  session,
}: {
  session: NearPaySession;
}) {
  const [select, setSelect] = useState<ViewModel>();

  return (
    <>
      <SlideOverUI
        title="Messages Details"
        isOpen={select ? true : false}
        close={() => setSelect(undefined)}
      >
        {select && <CardDetilsUI title="Messages" data={select.details} />}
        <br />
        {select &&
          select?.relations.map((item: any, i: number) => {
            return (
              <>
                <CardDetilsUI key={i} title={`EFTPOS Message`} data={item} />
                <br />
              </>
            );
          })}
      </SlideOverUI>

      <Table
        session={session}
        listName={"messages"}
        title={"Messages"}
        onClick={(item: ViewModel) => setSelect(item)}
      />
      <br />
    </>
  );
}
