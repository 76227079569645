import { useCallback, useEffect, useState } from "react";
import { NearPayAuth, NearPaySession } from "../api/core";
import Login, { LoginWithOtp } from "../ui/login";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Navigate } from "react-router-dom";

function LoginForm({
  setSession,
  auth,
}: {
  setSession: (session: NearPaySession) => void;
  auth: NearPayAuth;
}) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    onVerify();
  }, []);

  let loginAction = (
    email: string,
    password: string
  ): Promise<boolean | any> => {
    if (!executeRecaptcha) throw ["Recaptcha token is not valid any more !"];
    return executeRecaptcha().then(async (token) => {
      let session = await auth.login(email, password, token);
      if (session) {
        setSession(session);
      }
    });
  };

  let createOtpAction = (
    address: string,
    type: "mobile" | "email"
  ): Promise<any> => {
    if (!executeRecaptcha) throw ["Recaptcha token is not valid any more !"];
    return executeRecaptcha().then(async (token) => {
      return await auth.createOTPChallenge(type, address).catch((e) => {
        throw e;
      });
    });
  };

  let verifyOtpAction = (code: string): Promise<any> => {
    return auth.verifyOTPChallenge(code).then((session) => {
      if (session) {
        setSession(session);
      }
    });
  };

  if (process.env.REACT_APP_DASHBOARD_TYPE === "merchants")
    return (
      <LoginWithOtp
        createOTPChallenge={createOtpAction}
        verifyOTPChallenge={verifyOtpAction}
      />
    );
  return <Login loginAction={loginAction} />;
}

function LoginPage({
  setSession,
  auth,
}: {
  setSession: (session: NearPaySession) => void;
  auth: NearPayAuth;
}) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfETr0hAAAAAGhTpXh9z0SiBBe2Qcm3VUawtVCq"
      language="en"
      scriptProps={{
        async: true,
        defer: false,
        appendTo: "head",
      }}
    >
      <LoginForm setSession={setSession} auth={auth} />
    </GoogleReCaptchaProvider>
  );
}

export default LoginPage;
