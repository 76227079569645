import { ViewModel, ViewModelFilter } from "./base";
import { BankNameElement, DateElement } from "../elements/elements";
import { UsersStatus } from "../elements/users/elements";

export function UserModel(i: any): ViewModel {
  return getUserModel(i) as ViewModel;
}

const getUserModel = (i: any) => {
  switch (process.env.REACT_APP_DASHBOARD_TYPE) {
    case "accounts":
      return getAccountsUserModel(i);
    case "nsp":
      return getNspUserModel(i);
    case "merchants":
      return getMerchantsUserModel(i);
  }
};

const getAccountsUserModel = (i: any) => {
  let view = {
    Name: i.name,
    Status: UsersStatus(i.is_active),
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
const getMerchantsUserModel = (i: any) => {
  let view = {
    Name: i.name,
    Status: UsersStatus(i.is_active),
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
const getNspUserModel = (i: any) => {
  let view = {
    Name: i.name,
    Status: UsersStatus(i.is_active),
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
