import { Outlet } from "react-router-dom";
import Footer from "./ui/footer";
import NavSideBar from "./ui/nav-side-bar";
import { NearPaySession } from "./api/core";
import { RoutersGroups } from "./routes";

function App({
  session,
  groups,
}: {
  session: NearPaySession | undefined;
  groups: RoutersGroups[];
}) {
  if (!session)
    return (
      <div className="App ">
        <Outlet />
      </div>
    );

  let logout = () => {
    session.logout();
    window.location.reload();
  };

  return (
    <div className="App ">
      <NavSideBar
        logout={logout}
        groups={groups}
        profile={session.info()?.data}
      >
        <div
          className="mx-auto flex items-center justify-between"
          style={{ minHeight: "calc(100vh - 72px)", alignItems: "start" }}
        >
          <div className="flex-1 overflow-heddin" style={{ maxWidth: "100%" }}>
            <br />
            <Outlet />
          </div>
        </div>
      </NavSideBar>
      <Footer />
    </div>
  );
}

export default App;
