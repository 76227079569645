import { ViewModelFilter } from "../models/base";
import { DatePickerUI } from "./date.input";
import { XIcon } from "../utils/icons";

export function FilterUI({
  title,
  filters,
  updateFilter,
}: {
  title: string;
  filters: ViewModelFilter[];
  updateFilter?: (filters: ViewModelFilter[]) => void;
}) {
  return (
    <div className="bg-white">
      <div className="mx-auto  px-4 py-9 sm:px-6 lg:px-6 pb-4">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          {title}
        </h1>
      </div>

      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <div className="border-b border-gray-200 bg-white pb-4">
          <div className="mx-auto flex items-center justify-between px-4 sm:px-6 lg:px-8">
            <div className="hidden w-full sm:block">
              <div className="flow-root">
                <div className="-mx-4  grid grid-cols-4 lg:grid-cols-6 gap-2 items-center justify-between flex-wrap">
                  {filters.map((section, sectionIdx) => (
                    <div className="pr-2 w-full " key={sectionIdx}>
                      <div className="block text-start text-sm font-medium leading-6 text-gray-900 ">
                        {section.name}
                      </div>
                      {section.type === "options" ? (
                        <select
                          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value) {
                              section.selected = [value];
                            } else {
                              section.selected = [];
                            }
                            updateFilter && updateFilter(filters);
                          }}
                        >
                          <option
                            key={sectionIdx}
                            selected={!section.selected.length}
                          ></option>

                          {section.options?.map((opt) => (
                            <option
                              value={opt.value}
                              selected={
                                section.selected.find((i) => i === opt.value)
                                  ? true
                                  : false
                              }
                            >
                              {opt.name}
                            </option>
                          ))}
                        </select>
                      ) : section.type === "date_range" ? (
                        <DatePickerUI
                          clearable
                          startDate={new Date(Number(section.selected[0]))}
                          endDate={new Date(Number(section.selected[1]))}
                          setDate={(from: number, to: number) => {
                            if (from && to) {
                              section.selected[0] = String(from);
                              section.selected[1] = String(to);
                            } else {
                              section.selected = [];
                            }
                            updateFilter && updateFilter(filters);
                          }}
                        />
                      ) : (
                        <div
                          key={sectionIdx}
                          className="input-group relative w-full flex gap-2 items-center max-w-xs rounded-md border-0 bg-white py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <input
                            id={section.key}
                            pattern={section.pattern}
                            maxLength={section.maxLength}
                            type="text"
                            className=" border-0 p-0 !ring-0 bg-transparent"
                            onKeyUp={(e) => {
                              if (
                                e.code === "Enter" &&
                                e.currentTarget.value.match(
                                  e.currentTarget.pattern
                                )
                              ) {
                                section.selected = [e.currentTarget.value];
                                updateFilter && updateFilter(filters);
                              }
                            }}
                          />
                          <label
                            className="absolute end-2  cursor-pointer border-s !border-gray-300 !ring-0 px-1 hover:scale-105 hover:-translate-y-px active:translate-y-px text-gray-500 hover:text-gray-800 transition-all"
                            htmlFor={section.key}
                            onClick={(e) => {
                              if ((e.target as any).control.value === "") {
                                section.selected = [];
                                updateFilter && updateFilter(filters);
                              }
                              if (
                                !(e.target as any).control.value.match(
                                  section.pattern
                                )
                              )
                                return;
                              section.selected = [
                                (e.target as any).control.value,
                              ];
                              updateFilter && updateFilter(filters);
                            }}
                          >
                            set
                          </label>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="-mx-4 mt-2 flex items-center flex-wrap gap-3">
                {filters?.filter((i) => i.selected.length > 0).length ? (
                  <button
                    // type="submit"
                    onClick={(e) => {
                      updateFilter && updateFilter([]);
                    }}
                    className="flex justify-center rounded-md bg-gray-900 px-1 py-0.5 text-xs font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Clear Filters:{" "}
                    {filters?.filter((i) => i.selected.length > 0).length}{" "}
                    applied
                  </button>
                ) : null}

                {filters
                  ? filters
                      .filter((i) => i.selected.length && i.key != "date")
                      .map((filter, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-warp items-center gap-2"
                          >
                            <h3 className="text-sm"> {filter.name}: </h3>
                            {filter.selected.map((item, index) => (
                              <span className=" flex items-center justify-between text-xs text-black bg-gray-300 px-1 rounded">
                                <span>
                                  {filter && filter.options
                                    ? filter.options?.find(
                                        (i) => i.value === item
                                      )?.name
                                    : item}
                                </span>
                                <button
                                  className=""
                                  onClick={() => {
                                    filter.selected.splice(index, 1);
                                    updateFilter && updateFilter(filters);
                                  }}
                                >
                                  <XIcon
                                    containerClassNames="p-0"
                                    size={18}
                                    color="text-current"
                                  />
                                </button>
                              </span>
                            ))}
                          </div>
                        );
                      })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

// const FilterUi = (filter: ViewModelFilter,triggerUpdateFilter) => {
//   switch (filter.type) {
//     case "date":
//       return (

//       );
//     case "options":
//       break;
//     default:
//       break;
//   }
// };

/* <div className="p-2">
{ (
  <DatePickerUI
    startDate={new Date(from || 0)}
    endDate={new Date(to || new Date().getDate())}
    setDate={(from: number, to: number) => {
      setFrom(from);
      setTo(to);
    }}
  />
)}
</div> */
