import { Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { MouseEventHandler, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "./loading";

export function TableInfo({
  title,
  desc,
  children,
}: {
  children?: any;
  title?: string;
  desc?: string;
}) {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        {title && (
          <h1 className="text-lg font-bold leading-4 text-gray-900">{title}</h1>
        )}
        {desc && <p className="mt-2 text-sm text-gray-700">{desc}</p>}
      </div>
      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">{children}</div>
    </div>
  );
}

export function TableHeaderItem({ children }: { children: any }) {
  return (
    <th
      scope="col"
      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
    >
      {children}
    </th>
  );
}

export function TableRowItem({ children }: { children: any }) {
  return (
    <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6">
      {children}
    </td>
  );
}

export function TableUI({
  info,
  headers,
  children,
  footer,
  loading,
  empty,
}: {
  info?: JSX.Element;
  headers?: JSX.Element[];
  children?: JSX.Element[] | JSX.Element;
  footer?: JSX.Element;
  empty?: JSX.Element;
  loading?: boolean;
}) {
  return (
    <div className="">
      <div className="mt-4 flow-root ">
        <div className="  w-full overflow-auto">
          <div className="inline-block min-w-full py-4 align-middle ">
            <div
              className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg l:ml-0 l:mr-0 "
              style={{ marginLeft: "1px", marginRight: "1px" }}
            >
              <table className=" min-w-full divide-y divide-gray-300">
                <caption className="text-lg font-semibold text-left text-gray-900 bg-white">
                  {info}
                </caption>
                <thead className="bg-gray-50">
                  <tr>{headers}</tr>
                </thead>
                {loading ? (
                  <tbody className="relative py-12 h-52">
                    <Loading />
                  </tbody>
                ) : empty ? (
                  empty
                ) : (
                  <Transition
                    as="tbody"
                    className="divide-y divide-gray-200 bg-white"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    show={children ? true : false}
                  >
                    {children}
                  </Transition>
                )}
              </table>
              {footer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TableGroupRow({
  title,
  length,
}: {
  title: string;
  length: number;
}) {
  return (
    <tr className="text-sm leading-6 text-gray-900">
      <th
        scope="colgroup"
        colSpan={length}
        className="relative isolate py-2 font-semibold text-left p"
      >
        <time dateTime={title} className="px-5">
          {title}
        </time>
        <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
        <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
      </th>
    </tr>
  );
}
export function Pagination({
  total,
  total_pages,
  page,
  changePage,
}: {
  total: number;
  total_pages: number;
  page: number;
  changePage: (value: string) => void;
}) {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(page || 1);
  // let limit = total / total_pages;
  // let to = Math.ceil(limit * page);
  // let from = Math.ceil(to - limit);
  let pages = Array.from({ length: total_pages }).map((_, i) => i + 1);
  let normal =
    "relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex";
  let active =
    "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
  let selectNormal =
    "block w-full border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-semibold";
  let selectActive =
    "block w-full border-gray-300 py-2 pl-3 pr-10 text-base bg-indigo-600 text-white focus:z-20 focus:border-indigo-600 font-semibold focus:outline-none focus:ring-indigo-600 sm:text-sm";
  return (
    <div className="flex items-center justify-between border-t bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <Link
          to={`?page=${page > 1 ? page - 1 : page}`}
          className="relative inline-flex items-center rounded-md border bg-white border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </Link>
        <Link
          to={`?page=${page > 0 ? page + 1 : page}`}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </Link>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          {/* <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{from.toLocaleString()}</span>{" "}
            to <span className="font-medium">{to.toLocaleString()}</span> of{" "}
            <span className="font-medium">{total.toLocaleString()}</span>{" "}
            results
          </p> */}
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <Link
              to={`?page=${page > 1 ? page - 1 : page}`}
              className={
                (page == 1 ? "pointer-events-none " : "") +
                "relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              }
              onClick={() => setCurrent(page - 1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>

            <input
              type="text"
              className={
                active + " w-12 text-center !py-0 !px-0 invalid:bg-red-400"
              }
              value={current}
              pattern="[0-9]+"
              onChange={(e) => {
                if (e.target.checkValidity()) {
                  setCurrent(Number(e.target.value));
                }
                // if (!e.target.value) setCurrent(1);
              }}
              onKeyUp={(e) => {
                if (
                  e.key === "Enter"
                  // &&
                  // Number(e.currentTarget.value) <= total_pages
                )
                  navigate("?page=" + current);
              }}
            />

            <Link
              to={`?page=${page > 0 && page != total_pages ? page + 1 : page}`}
              className={
                (page == total_pages ? "pointer-events-none " : "") +
                "relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              }
              onClick={() => setCurrent(page + 1)}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export function TableTR({
  children,
  onClick,
}: {
  children: any;
  onClick?: any;
}) {
  return (
    <Transition.Child
      as={"tr"}
      className="cursor-pointer"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      appear={true}
      onClick={onClick}
    >
      {children}
    </Transition.Child>
  );
}

export function Button({
  children,
  onClick,
}: {
  onClick?: MouseEventHandler;
  children: any;
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className=" block rounded-md bg-black px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {children}
    </button>
  );
}

export const Table = {
  Body: TableUI,
  Info: TableInfo,
  Header: TableHeaderItem,
  Row: TableRowItem,
  TR: TableTR,
};
