export const ReconciliationsStatus = (is_balanced: boolean) => {
  return (
    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
      {is_balanced ? (
        <div className="flex-none rounded-full p-1 text-green-400 bg-green-400/10">
          <div className="h-3 w-3 rounded-full bg-current"></div>
        </div>
      ) : (
        <div className="flex-none rounded-full p-1 text-red-400 bg-red-400/10">
          <div className="h-3 w-3 rounded-full bg-current"></div>
        </div>
      )}
      <div className="whitespace-pre-wrap">
        <small> {is_balanced ? "Balanced" : "Not Balanced"}</small>
      </div>
    </div>
  );
};

export const ReconciliationAmount = (amount: number, currency: string) => (
  <>
    <small>{currency}</small>
    <b>{amount.toFixed(2)?.toLocaleString()}</b>
  </>
);
