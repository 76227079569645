import { XCircleIcon } from "@heroicons/react/20/solid";

export default function ErrorUI({
  title,
  errors,
}: {
  title: string;
  errors: string[];
}) {
  return (
    <div className="rounded-md bg-white p-4 border-l-2 border-red-400">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {errors?.length &&
                errors.map((error, i) => <li key={i}>{error}</li>)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
