import { DateElement } from "../elements/elements";

export interface ViewModelFilter {
  name: string;
  key: string;
  options?: { name: string; value: string }[];
  type: "options" | "search" | "date" | "date_range";
  selected: string[];
  pattern?: string;
  maxLength?: number;
  inputTransformer?: (value: string) => string;
}
export interface ViewModel {
  raw: Record<string, any>;
  list: Record<string, JSX.Element | string>;
  details: Record<string, JSX.Element | string>;
  relations: Record<string, JSX.Element>[];
}

export function BaseModel(i: any): ViewModel {
  let keys = Object.keys(i);
  let view = keys.reduce((obj: any, key) => {
    if (key === "created_at") {
      obj["Date"] = (
        <DateElement date={i.created_at} opt={{ relative: false }} />
      );
      return obj;
    }
    if (typeof i[key] === "string") obj[key.toLocaleUpperCase()] = i[key];
    if (typeof i[key] === "number") obj[key.toLocaleUpperCase()] = i[key];
    if (typeof i[key] === "boolean")
      obj[key.toLocaleUpperCase()] = i[key] ? "Enabled" : "Disabled";
    return obj;
  }, {});
  return {
    list: view,
    details: view,
    relations: [],
    raw: i,
  };
}
