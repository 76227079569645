import { useEffect, useState } from "react";
import { ViewModel, ViewModelFilter } from "./models/base";
import { Pagination, Table } from "./ui/table";
import { useSearchParams } from "react-router-dom";
import { NearPaySession } from "./api/core";
import { Loading } from "./ui/loading";
import EmptyUI from "./ui/empty";
import { FilterUI } from "./ui/filters";

function getColumns(list: Record<string, any>) {
  return Object.keys(list);
}

export default function TableList({
  session,
  listName,
  title,
  onClick,
  actionButton,
}: {
  session: NearPaySession;
  listName: string;
  onClick: (item: ViewModel) => void;
  title: string;
  actionButton?: JSX.Element;
}) {
  let [list, setList] = useState<ViewModel[] | undefined>();
  const [query, setQuery] = useState<ViewModelFilter[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [listIsNotLoaded, setListIsNotLoaded] = useState(true);
  let [meta, setMeta] = useState<any>();
  let [from, setFrom] = useState<number | undefined>(undefined);
  let [to, setTo] = useState<number | undefined>(undefined);
  const [header, setHeader] = useState<JSX.Element | undefined>(undefined);

  let loadList = async () => {
    try {
      try {
        const data = await session.list(
          listName,
          searchParams.get("page") || "1",
          query.reduce((obj: any, i) => {
            if (i.type === "date_range") {
              if (i.selected[0]) setFrom(Number(i.selected[0]));
              if (i.selected[1]) setTo(Number(i.selected[1]));
            } else if (i.type === "date") {
              obj[i.key] = i.selected[0];
            } else {
              obj[i.key] = i.selected;
            }
            return obj;
          }, {}) as any,
          from,
          to
        );
        setList(data[listName]);
        if (!query.length && data.filters) {
          setQuery(data?.filters);
        }
        setMeta(data.meta);
      } catch (e) {
        console.log({ e });
      }
    } finally {
      setLoading(false);
      setListIsNotLoaded(false);
    }
  };

  useEffect(() => {
    loadList();
  }, [searchParams, from, to]);

  useEffect(() => {
    if (query.some((i) => i.selected.length)) {
      loadList();
    }
    setHeader(
      <FilterUI
        title={title}
        filters={query}
        updateFilter={(filters) => {
          setQuery([...filters]);
          setSearchParams("page=1");
        }}
      />
    );
  }, [query]);
  if (listIsNotLoaded) {
    return <Loading />;
  }
  if (!list?.length)
    return (
      <Table.Body info={header}>
        <Table.TR>
          <Table.Row>
            <EmptyUI
              message={"No Data"}
              onClick={() => {
                setQuery([]);
              }}
            />
          </Table.Row>
        </Table.TR>
      </Table.Body>
    );

  if (!list.length) return <></>;
  let columns = getColumns(list[0]?.list);

  let rows = list?.map((item: any, i: number) => (
    <Table.TR key={i} onClick={() => onClick(item)}>
      {columns.map((col, col_i) => (
        <Table.Row key={col_i}>{item.list[col]}</Table.Row>
      ))}
    </Table.TR>
  ));

  let headers = columns?.map((col, i) => (
    <Table.Header key={i}>{col}</Table.Header>
  ));
  let footer;
  // if (meta.count && meta.total_pages && meta.page)
  if (list.length)
    footer = (
      <Pagination
        total={meta.count}
        total_pages={meta.total_pages}
        page={meta.page}
        changePage={(value: string) => setSearchParams("page=" + value)}
      />
    );

  return (
    <Table.Body
      loading={loading}
      info={header}
      headers={headers}
      footer={footer}
    >
      {rows}
    </Table.Body>
  );
}
