export default function Footer() {
  return (
    <footer className="bg-gray-200 mt-0">
      <div className="mx-auto max-w-7xl p-8  ">
        <div className="md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">&copy; 2023 NearPay, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
