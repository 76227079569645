import { useEffect, useState } from "react";
import ErrorUI from "./error-ui";
import Logo from "../logo-black.svg";

export default function Login({
  loginAction,
}: {
  loginAction: (email: string, password: string) => Promise<boolean>;
}) {
  let [email, setEmail] = useState<string>("");
  let [password, setPassword] = useState<string>("");
  let [errors, setErrors] = useState<string[]>([]);
  let submit = (e: any) => {
    e.preventDefault();
    setErrors([]);
    if (email && password) {
      loginAction(email, password).catch((error) => {
        setErrors(error);
      });
    }
  };
  return (
    <>
      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={Logo} alt="NearPay Logo" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {errors.length > 0 ? (
            <ErrorUI
              title={`You have ${errors.length} errors`}
              errors={errors}
            ></ErrorUI>
          ) : (
            ""
          )}
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={(e) => submit(e)}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  autoComplete="email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  {/* <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a> */}
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                // type="submit"
                onClick={(e) => submit(e)}
                className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <a
              href="#"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export const LoginWithOtp = ({
  createOTPChallenge,
  verifyOTPChallenge,
}: {
  createOTPChallenge: (
    address: string,
    type: "mobile" | "email"
  ) => Promise<any>;
  verifyOTPChallenge: (code: string) => void;
}) => {
  let [email, setEmail] = useState<string>("");
  let [mobile, setMobile] = useState<string>("");
  let [type, setType] = useState("email");
  let [otp, setOtp] = useState<string>("");
  let [showOtpInput, setShowOtpInput] = useState<boolean>(false);
  let [errors, setErrors] = useState<string[]>([]);
  let submit = (e: any) => {
    setErrors([]);
    e.preventDefault();
    if (otp === "") {
      if (mobile)
        createOTPChallenge(mobile, "mobile")
          .then(() => setShowOtpInput(true))
          .catch((error) => {
            setErrors(error);
          });
      if (email)
        createOTPChallenge(email, "email")
          .then(() => setShowOtpInput(true))
          .catch((error) => {
            setErrors(error);
          });
    } else e.preventDefault();
  };
  let changeType = (type: string) => {
    setType(type);
    setShowOtpInput(false);
  };

  let verify = (e: any) => {
    try {
      verifyOTPChallenge(otp);
    } catch (error) {}
  };

  return (
    <>
      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={Logo} alt="NearPay Logo" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {errors.length > 0 ? (
            <ErrorUI
              title={`You have ${errors.length} errors`}
              errors={errors}
            ></ErrorUI>
          ) : (
            ""
          )}

          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={(e) => submit(e)}
          >
            <span className="isolate inline-flex rounded-md shadow-sm w-full">
              <button
                onClick={() => changeType("email")}
                disabled={type === "email"}
                type="button"
                className="w-full relative inline-flex items-center rounded-l-md bg-white disabled:bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              >
                Email
              </button>

              <button
                disabled={type === "mobile"}
                onClick={() => changeType("mobile")}
                type="button"
                className="w-full relative -ml-px inline-flex items-center rounded-r-md bg-white disabled:bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              >
                Mobile
              </button>
            </span>
            {type === "email" ? (
              <>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      autoComplete="email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </>
            ) : null}
            {showOtpInput ? (
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="otp"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    OTP
                  </label>
                  <div className="text-sm"></div>
                </div>
                <div className="mt-2">
                  <input
                    id="otp"
                    name="otp"
                    type="otp"
                    value={otp}
                    onChange={(event) => setOtp(event.target.value)}
                    autoComplete="current-otp"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            ) : null}
            <div>
              <button
                // type="submit"
                onClick={(e) => (showOtpInput ? verify(e) : submit(e))}
                className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <a
              href="#"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
