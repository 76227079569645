import { ViewModel, ViewModelFilter } from "./base";
import { BankNameElement, DateElement } from "../elements/elements";

export function AppModel(i: any): ViewModel {
  return getAppModel(i) as ViewModel;
}

const getAppModel = (i: any) => {
  switch (process.env.REACT_APP_DASHBOARD_TYPE) {
    case "accounts":
      return getAccountsAppModel(i);
    case "nsp":
      return getNspAppModel(i);
  }
};

const getAccountsAppModel = (i: any) => {
  let view = {
    "App Name": i.name,
    "Package Name": i.package_name,
    Type: i.type,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
const getNspAppModel = (i: any) => {
  let view = {
    "App Name": i.name,
    "Package Name": i.package_name,
    Type: i.type,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
