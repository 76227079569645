import { useState } from "react";
import { NearPaySession } from "../../api/core";
import CardDetilsUI from "../../ui/card-details";
import SlideOverUI from "../../ui/slide-overs";
import Table from "../../table";

export default function AccountsAppsPage({
  session,
}: {
  session: NearPaySession;
}) {
  const [select, setSelect] = useState<any>();
  return (
    <>
      <SlideOverUI
        title="App Details"
        isOpen={select ? true : false}
        close={() => setSelect(undefined)}
      >
        {select && <CardDetilsUI title="Apps" data={select.details} />}
        <br />
      </SlideOverUI>
      <Table
        session={session}
        listName={"client_apps"}
        title="Apps"
        onClick={(item: any) => setSelect(item)}
      />
      <br />
    </>
  );
}
