export default function CardDetilsUI({
  title,
  data,
}: {
  title: string;
  data: any;
}) {
  return (
    <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-4">
        <div className="text-sm font-bold leading-6 text-black">{title}</div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        {data &&
          Object.keys(data).map((k, i) => {
            return (
              <div
                key={k}
                className="flex gap-x-4 py-2 font-semibold items-center flex-wrap"
              >
                <dt className="text-gray-500" style={{ minWidth: "120px" }}>
                  {k}
                </dt>
                <dd className="text-black break-all">{data[k]}</dd>
              </div>
            );
          })}
      </dl>
    </div>
  );
}

export function ReconciliationsCardDetailsUI({
  title,
  data,
}: {
  title: string;
  data: any;
}) {
  let { Name, ...other } = data;
  return (
    <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-4">
        <div className="text-sm font-bold leading-6 text-black w-full text-center">
          {title}
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="flex gap-x-4 py-2 font-semibold items-center flex-wrap">
          <dt className="text-gray-500" style={{ minWidth: "120px" }}></dt>
          <dt className="text-black break-all " style={{ minWidth: "120px" }}>
            HOST
          </dt>
          <dt className="text-black break-all" style={{ minWidth: "120px" }}>
            POS
          </dt>
        </div>
        {other &&
          Object.keys(other).map((k: any, i) => {
            return (
              <div
                key={i}
                className="flex gap-x-4 py-2 font-semibold items-center flex-wrap"
              >
                <dt className="text-gray-500" style={{ minWidth: "120px" }}>
                  {k}
                </dt>

                <dd
                  className="text-black break-all"
                  style={{ minWidth: "120px" }}
                >
                  {other[k]["HOST"]}
                </dd>
                <dd
                  className="text-black break-all"
                  style={{ minWidth: "120px" }}
                >
                  {other[k]["POS"]}
                </dd>
              </div>
            );
          })}
      </dl>
    </div>
  );
}
