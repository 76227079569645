import { ChartPieIcon } from "@heroicons/react/20/solid";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import {
  AppleIcon,
  CreditCardIcon,
  LayoutGridIcon,
  ScaleIcon,
  StoreIcon,
  User2Icon,
  UserIcon,
  UsersIcon,
  WebhookIcon,
} from "lucide-react";
import { NearPaySession } from "./api/core";
import AccountsAdminsPage from "./pages/accounts/admins.page";
import AccountsAppsPage from "./pages/accounts/apps.page";
import AccountsMerchantsPage from "./pages/accounts/merchants.page";
import AccountsReconciliationsPage from "./pages/accounts/reconciliations.page";

import AccountsTerminalsPage from "./pages/accounts/terminals.page";
import AccountsTransactionsPage from "./pages/accounts/transactions.page";
import AccountsWebhooksPage from "./pages/accounts/webhooks.page";
import NSPMessagesPage from "./pages/nsp/messages.page";
import NSPReportsPage from "./pages/nsp/reports.page";
import NSPTerminalsPage from "./pages/nsp/terminals.page";
import NSPTransactionsPage from "./pages/nsp/transactions.page";

export interface RouterNavLink {
  name: string;
  link: string;
  icon: any;
}

export interface RoutersGroups {
  title: string;
  path: string;
  links: RouterNavLink[];
}

export interface RoutesPages {
  path: string;
  component: ({ session }: { session: NearPaySession }) => JSX.Element;
}
export interface RoutesGroups {
  accounts: RoutesPages[];
  merchants: RoutesPages[];
  nsp: RoutesPages[];
}
// const PlatofrmsLinks: RouterNavLink[] = [
//   { name: 'Reports', link: '/platforms/reports', icon: ChartPieIcon },
//   { name: 'Accounts', link: '/platforms/accounts', icon: FolderIcon },
//   { name: 'Merchants', link: '/platforms/merchants', icon: HomeIcon },
//   { name: 'Terminals', link: '/platforms/terminals', icon: CalendarIcon },
//   {
//     name: 'Transactions',
//     link: '/platforms/transactions',
//     icon: DocumentDuplicateIcon,
//   },
//   {
//     name: 'Reconciliations',
//     link: '/platforms/reconciliations',
//     icon: ChartPieIcon,
//   },
// ];
// const AccountsLinks: RouterNavLink[] = [
//   { name: 'Reports', link: '/accounts/reports', icon: ChartPieIcon },
//   { name: 'Merchants', link: '/accounts/merchants', icon: HomeIcon },
//   { name: 'Terminals', link: '/accounts/terminals', icon: CalendarIcon },
//   {
//     name: 'Transactions',
//     link: '/accounts/transactions',
//     icon: DocumentDuplicateIcon,
//   },
//   {
//     name: 'Reconciliations',
//     link: '/accounts/reconciliations',
//     icon: ChartPieIcon,
//   },
// ];
// const MerchantsLinks: RouterNavLink[] = [
//   { name: 'Reports', link: '/merchants/reports', icon: ChartPieIcon },
//   { name: 'Terminals', link: '/merchants/terminals', icon: CalendarIcon },
//   {
//     name: 'Transactions',
//     link: '/merchants/transactions',
//     icon: DocumentDuplicateIcon,
//   },
//   {
//     name: 'Reconciliations',
//     link: '/merchants/reconciliations',
//     icon: ChartPieIcon,
//   },
// ];

const NSPLinks: RouterNavLink[] = [
  // { name: "Reports", link: "/", icon: ChartPieIcon },
  {
    name: "Transactions",
    link: "/transactions",
    icon: CreditCardIcon,
  },
  { name: "Terminals", link: "/terminals", icon: DevicePhoneMobileIcon },
  { name: "Messages", link: "/messages", icon: EnvelopeIcon },
];

const AccountsLinks: RouterNavLink[] = [
  {
    name: "Merchants",
    link: "/merchants",
    icon: StoreIcon,
  },
  {
    name: "Terminals",
    link: "/terminals",
    icon: DevicePhoneMobileIcon,
  },
  {
    name: "Transactions",
    link: "/transactions",
    icon: CreditCardIcon,
  },
  {
    name: "Reconciliations",
    link: "/reconciliations",
    icon: ScaleIcon,
  },
  {
    name: "Team",
    link: "/admins",
    icon: UsersIcon,
  },
  {
    name: "Apps",
    link: "/apps",
    icon: LayoutGridIcon,
  },
  {
    name: "Webhooks",
    link: "/webhooks",
    icon: WebhookIcon,
  },
];

const MerchantsLinks: RouterNavLink[] = [
  {
    name: "Terminals",
    link: "/terminals",
    icon: DevicePhoneMobileIcon,
  },
  {
    name: "Transactions",
    link: "/transactions",
    icon: CreditCardIcon,
  },
  {
    name: "Reconciliations",
    link: "/reconciliations",
    icon: ScaleIcon,
  },
  {
    name: "Team",
    link: "/users",
    icon: UsersIcon,
  },
];

const NspRouterGroupsLinks: RoutersGroups[] = [
  {
    title: "NSP",
    path: "/nsp",
    links: NSPLinks,
  },
];

const AccountsRouterGroupsLinks: RoutersGroups[] = [
  {
    title: "Accounts",
    path: "/accounts",
    links: AccountsLinks,
  },
];

const MerchantsRouterGroupsLinks: RoutersGroups[] = [
  {
    title: "Merchants",
    path: "/merchants",
    links: MerchantsLinks,
  },
];

export const RouterGroupsLinks = {
  accounts: AccountsRouterGroupsLinks,
  merchants: MerchantsRouterGroupsLinks,
  nsp: NspRouterGroupsLinks,
};

export const RoutesPages: RoutesGroups = {
  accounts: [
    {
      path: "/",
      component: AccountsMerchantsPage,
    },
    {
      path: "/merchants",
      component: AccountsMerchantsPage,
    },
    {
      path: "/terminals",
      component: AccountsTerminalsPage,
    },
    {
      path: "/transactions",
      component: AccountsTransactionsPage,
    },
    {
      path: "/reconciliations",
      component: AccountsReconciliationsPage,
    },
    {
      path: "/admins",
      component: AccountsAdminsPage,
    },
    {
      path: "/apps",
      component: AccountsAppsPage,
    },
    {
      path: "/webhooks",
      component: AccountsWebhooksPage,
    },
  ],
  merchants: [],
  nsp: [
    // {
    //   path: "/",
    //   component: NSPReportsPage,
    // },
    {
      path: "/",
      component: NSPTransactionsPage,
    },
    {
      path: "/terminals",
      component: NSPTerminalsPage,
    },
    {
      path: "/transactions",
      component: NSPTransactionsPage,
    },
    {
      path: "/messages",
      component: NSPMessagesPage,
    },
  ],
};
