import { useState } from 'react';

function TreeLeaf({ key, item }: { key: string; item: any }) {
  let [open, setOpen] = useState<boolean>(false);
  return (
    <li>
      <span
        className={open ? 'caret caret-down' : 'caret'}
        onClick={() => setOpen(!open)}
      >
        {key?.toLocaleUpperCase()}
      </span>
      <ul className={open ? 'nested active' : 'nested'}>
        <TreeUI data={item} />
      </ul>
    </li>
  );
}

export function TreeUI({ data }: { data: any }) {
  return (
    <ul>
      {Object.keys(data).map((i) => {
        if (typeof data[i] === 'object')
          return <TreeLeaf key={i} item={data[i]} />;
        else
          return (
            <li>
              {i.toLocaleUpperCase()}: {data[i]}
            </li>
          );
      })}
    </ul>
  );
}
