import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

export default function EmptyUI({
  message,
  desc,
  onClick,
}: {
  message: string;
  desc?: string;
  onClick?: any;
}) {
  return (
    <div className="text-center">
      <ExclamationTriangleIcon
        className="mx-auto h-12 w-12 text-gray-400"
        aria-hidden="true"
      />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{message}</h3>
      <p className="mt-1 text-sm text-gray-500">{desc}</p>
      <div className="mt-6">
        {onClick && (
          <button
            onClick={onClick}
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <ArrowPathIcon
              className="-ml-0.5 mr-1.5 h-5 w-5"
              aria-hidden="true"
            />
            Refresh
          </button>
        )}
      </div>
    </div>
  );
}
