import { ViewModel, ViewModelFilter } from "./base";
import { BankNameElement, DateElement } from "../elements/elements";
import { banks } from "../utils/banks";
import { MerchantStatus } from "../elements/merchants/elements";

export function MerchantQuery(): ViewModelFilter[] {
  return [
    {
      name: "Reference Id",
      key: "reference_id",
      type: "search",
      selected: [],
    },
  ];
}

export function MerchantsModel(i: any): ViewModel {
  return getMerchantsModel(i) as ViewModel;
}

export function MerchantsQuery(): ViewModelFilter[] {
  return [
    {
      name: "Created In",
      key: "date",
      type: "date_range",
      selected: [],
    },
  ];
}

export const getMerchantsModel = (i: any) => {
  switch (process.env.REACT_APP_DASHBOARD_TYPE) {
    case "accounts":
      return getAccountsMerchantModel(i);
    case "nsp":
      return getNspMerchantModel(i);
  }
};

/**
created_at: 
"2023-07-03T08:40:41.698Z"
id: 
"9c3088e2-5e4b-4080-8b4a-dc3dbd54c55f"
name: 
"NearPay-Test Terminal INMA"
name_ar: 
"123455"
reference_id: 
null
status: 
"active"
updated_at: 
"2023-07-03T08:40:41.698Z"
 */
const getAccountsMerchantModel = (i: any) => {
  let view = {
    Name: <code className="font-semibold">{i.name}</code>,
    "Arabic Name": <code className="font-semibold">{i.name_ar}</code>,
    Status: <code className="font-semibold">{MerchantStatus(i.status)} </code>,
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      ID: <code className="font-semibold">{i.id}</code>,
      "Reference ID": <code className="font-semibold">{i.reference_id}</code>,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
const getNspMerchantModel = (i: any) => {
  let view = {
    Name: <code className="font-semibold">{i.name}</code>,
    "Arabic Name": <code className="font-semibold">{i.name_ar}</code>,
    Status: <code className="font-semibold">{MerchantStatus(i.status)} </code>,
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      ID: <code className="font-semibold">{i.id}</code>,
      "Reference ID": <code className="font-semibold">{i.reference_id}</code>,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: [{}],
    raw: i,
  };
};
