import { ViewModelFilter } from "./../models/base";
import { BaseModel, ViewModel } from "../models/base";
import { MessageQuery, MessagesModel } from "../models/messages.model";
import { TerminalsModel, TerminalsQuery } from "../models/terminals.model";
import {
  TransactionQuery,
  transactionModel,
} from "../models/transactions.model";
import { queryPack } from "../utils/query.packer";
import { MerchantQuery, MerchantsModel } from "../models/merchants.model";
import {
  ReconciliationModel,
  ReconciliationQuery,
} from "../models/reconciliations.model";
import { UserModel } from "../models/users.model";
import { AppModel } from "../models/apps.model";
import { WebhookModel, WebhookQuery } from "../models/webhooks.model";

class API {
  private jwt: string | null = null;
  private Auth: NearPayAuth | undefined;
  private headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  constructor(
    public base_url: string,
    private onUnauthorized: () => Promise<void>
  ) {}
  setHeaders(key: string, value: string) {
    this.headers[key] = value;
  }

  setAuth(Auth: any) {
    this.Auth = Auth;
  }

  setJWT(jwt: string) {
    this.jwt = jwt;
    this.headers["Authorization"] = "Bearer " + this.jwt;
  }
  private async call(
    method: "GET" | "POST" | "PUT" | "DELETE",
    path: string,
    data?: any,
    query?: Record<string, string>
  ) {
    // let auth = new NearPayAuth();
    let body: string | null | undefined;
    if (query) path = path + "?" + queryPack(query);
    if (data) body = JSON.stringify(data);
    let res;
    try {
      res = await fetch(this.base_url + path, {
        method,
        body,
        headers: { ...this.headers },
      })
        .then(async (res) => {
          if (res.ok) return res.json();
          let response = await res.json();
          if (response?.statusCode === 401) {
            if (path.includes("refresh-access-token")) throw "unauth";
            await this.onUnauthorized();

            return await fetch(this.base_url + path, {
              method,
              body,
              headers: { ...this.headers },
            }).then((res) => {
              if (res.ok) return res.json();
              return res.json();
            });
          } else {
            throw response;
          }
        })
        .catch((error_1) => {
          throw error_1;
        });
      return res;
    } catch (error_1: any) {
      throw error_1;
    }
  }
  Get(path: string, query?: Record<string, any>) {
    return this.call("GET", path, null, query);
  }
  Post(path: string, data: any) {
    return this.call("POST", path, data);
  }
  Delete(path: string, data: any) {
    return this.call("POST", path, data);
  }
  Put(path: string, data: any) {
    return this.call("POST", path, data);
  }
}

interface AuthError {
  message: string[];
}

class JWTStore {
  private data: null | {
    accessToken: string | null;
    refreshToken: string | null;
  } = {
    accessToken: localStorage.getItem(this.access_key_name),
    refreshToken: localStorage.getItem(this.refresh_key_name),
  };
  constructor(private key: string) {}
  find() {
    return this.data;
  }
  get access_key_name() {
    return this.key + "_accessToken";
  }
  get refresh_key_name() {
    return this.key + "_refreshToken";
  }
  create(accessToken: string, refreshToken: string) {
    localStorage.setItem(this.access_key_name, accessToken);
    localStorage.setItem(this.refresh_key_name, refreshToken);
    this.data = {
      accessToken: localStorage.getItem(this.access_key_name),
      refreshToken: localStorage.getItem(this.refresh_key_name),
    };
  }
  clear() {
    localStorage.removeItem(this.access_key_name);
    localStorage.removeItem(this.refresh_key_name);
    this.data = { accessToken: null, refreshToken: null };
  }
}

export class NearPaySession {
  models: Record<
    string,
    { model: (i: any) => ViewModel; filters?: () => ViewModelFilter[] }
  > = {
    base: { model: BaseModel },
    transactions: { model: transactionModel, filters: TransactionQuery },
    messages: { model: MessagesModel, filters: MessageQuery },
    terminals: { model: TerminalsModel, filters: TerminalsQuery },
    merchants: { model: MerchantsModel, filters: MerchantQuery },
    reconciliations_receipts: {
      model: ReconciliationModel,
      filters: ReconciliationQuery,
    },
    admins: { model: UserModel },
    users: { model: UserModel },
    client_apps: { model: AppModel },
    webhook: { model: WebhookModel, filters: WebhookQuery },
  };
  constructor(
    private accessToken: string,
    private refreshToken: string,
    private store: JWTStore,
    private api: API
  ) {
    this.store.create(this.accessToken, this.refreshToken);
  }

  logout() {
    this.store.clear();
    return true;
  }

  async list(
    modelName: string,
    page: string,
    where?: any,
    from?: number,
    to?: number
  ) {
    let model = this.models[modelName];
    if (!model) model = this.models.base;
    let list = await this.api.Get(
      process.env.REACT_APP_DASHBOARD_TYPE + "/data/" + modelName,
      {
        page,
        where,
        from,
        to,
      }
    );

    return {
      [modelName]: list[modelName].map(model.model),
      meta: list.meta,
      filters: model.filters?.(),
    };
  }
  async reports() {
    return this.api.Get("nsp/reports").catch((error) => {});
  }
  async detailReports(from: number, to: number, view: string) {
    return this.api.Get("nsp/reports/details", { from, to, view });
  }
  info(): {
    data: {
      version: number;
      name: string;
      client: { id: string; name: string; is_nsp: boolean };
      access: { merchant: { id: string; name: string } }[];
      user?: {
        id: string;
        name?: string;
        email?: string;
        mobile?: string;
        agent?: string;
        jwt_id?: string;
      };
    };
  } | null {
    try {
      return JSON.parse(atob(this.accessToken.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
}

export class NearPayAuth {
  private currentSession: NearPaySession | null = null;
  private store: JWTStore = new JWTStore("np_session");
  private challengeId: string | undefined;
  private address: string | undefined; // tmp till we move to the core
  private api: API = new API(process.env.REACT_APP_BASEURL as string, () =>
    this.refresh()
  );
  constructor() {
    this.api.setAuth(this);
  }

  async getSession(): Promise<NearPaySession | null> {
    if (this.currentSession) return this.currentSession;
    let jwt = this.store.find();
    if (jwt?.accessToken && jwt?.refreshToken) {
      this.api.setJWT(jwt.accessToken);
      let session = new NearPaySession(
        jwt.accessToken,
        jwt.refreshToken,
        this.store,
        this.api
      );
      this.currentSession = session;
      this.api.setHeaders(
        "client_id",
        this.currentSession.info()?.data.client?.id as string
      );
      this.api.setHeaders(
        "merchant_id",
        this.currentSession.info()?.data.access?.[0]?.merchant?.id as string
      );
      return this.currentSession;
    } else {
      return null;
    }
  }

  async refresh() {
    try {
      let jwt = this.store.find();
      let endpoint =
        process.env.REACT_APP_DASHBOARD_TYPE === "merchants"
          ? "v1/merchants/refresh-access-token"
          : "v1/clients/refresh-access-token";
      if (!jwt || !jwt.accessToken || !jwt.refreshToken) {
        window.location.reload();
        return;
      }
      let result = await this.api.Post(endpoint, {
        refreshToken: jwt.refreshToken,
        is_nsp: process.env.REACT_APP_DASHBOARD_TYPE === "nsp",
      });
      this.store.create(result.accessToken, jwt.refreshToken);
      this.api.setJWT(result.accessToken);
    } catch (error) {
      this.logout();
      throw "Unauthorized Error";
    }
  }

  async logout(): Promise<boolean> {
    if (!this.currentSession) {
      window.location.reload();
      return true;
    }
    return this.currentSession.logout();
  }

  async createOTPChallenge(
    type: "mobile" | "email",
    address: string
  ): Promise<AuthError | boolean> {
    let endpoint =
      type === "email" ? "v1/merchants/login/email" : "v1/merchants/login";
    await this.api
      .Post(endpoint, {
        email: address.toLocaleLowerCase(),
        mobile: address,
      })
      .then((response) => {
        this.challengeId = response.id;
        this.address = address;
      });
    return true;
  }
  async verifyOTPChallenge(code: string): Promise<NearPaySession | undefined> {
    let response = await this.api.Post("v1/merchants/login/email/verify", {
      email: this.address?.toLocaleLowerCase(),
      code,
    });
    return new NearPaySession(
      response.accessToken,
      response.refreshToken,
      this.store,
      this.api
    );
  }

  async resend(): Promise<boolean> {
    // send API
    return true;
  }

  async login(
    email: string,
    password: string,
    recaptchaToken: string
  ): Promise<NearPaySession | undefined> {
    // send API
    try {
      this.api.setHeaders("recaptchaToken", recaptchaToken);
      let nearpay_session = await this.api
        .Post("v1/clients/login/v2", {
          email,
          password,
          is_nsp: process.env.REACT_APP_DASHBOARD_TYPE === "nsp",
        })
        .then((response) => {
          this.api.setJWT(response.accessToken);
          return new NearPaySession(
            response.accessToken,
            response.refreshToken,
            this.store,
            this.api
          );
        });
      this.api.setHeaders(
        "client_id",
        nearpay_session.info()?.data.client?.id as string
      );
      this.api.setHeaders(
        "merchant_id",
        nearpay_session.info()?.data.access?.[0]?.merchant?.id as string
      );
      return nearpay_session;
    } catch (error: any) {
      if (error && error.message) {
        let list: string[] = [];
        if (typeof error.message === "string") {
          list.push(error.message);
        } else {
          Object.keys(error.message).forEach((key) => {
            console.log(key);
            error.message[key].forEach((m: string) => list.push(m));
          });
        }
        throw list;
      } else if (error.code) {
        let list: string[] = [error.error.english];
        throw list;
      }
    }
  }
}

// export class CoreAPI {
//   constructor(auth: NearPaySession) {}
// }
