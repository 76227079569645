export function queryPack(query: Record<string, any>, parent_key?: string): string {
  if (Array.isArray(query)) return query.reduce((s, v, i) => s + `[${parent_key}][${i}]=${v}&`, '');
  if (typeof query === 'object') {
    let keys = Object.keys(query);
    return keys
      .map((key, i) => {
        if (Array.isArray(query[key])) return query[key].reduce((s: string, v: string, i: number) => s + `${parent_key}[${key}][${i}]=${v}&`, '');
        if (typeof query[key] === 'object') return queryPack(query[key], key);
        if (typeof query[key] === 'number') return `${key}=${query[key]}&`;
        if (typeof query[key] === 'string') return `${key}=${query[key]}&`;
        else return query[key] ? `${key}=${query[key]}` : '';
      })
      .reduce((s, v) => s + v, '');
  } else {
    return '';
  }
}
