export const MerchantStatus = (status: string) => {
  let is_active = status === "active";

  return (
    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
      {is_active ? (
        <div className="flex-none rounded-full p-1 text-green-400 bg-green-400/10">
          <div className="h-3 w-3 rounded-full bg-current"></div>
        </div>
      ) : (
        <div className="flex-none rounded-full p-1 text-red-400 bg-red-400/10">
          <div className="h-3 w-3 rounded-full bg-current"></div>
        </div>
      )}
      <div className="whitespace-pre-wrap">
        {status.toLocaleUpperCase()}
        {/* <small> {!state?.approved && `(${state.code})`}</small> */}
      </div>
    </div>
  );
};
