import { ViewModel, ViewModelFilter } from "./base";
import { BankNameElement, DateElement } from "../elements/elements";
import {
  ReconciliationAmount,
  ReconciliationsStatus,
} from "../elements/reconciliations/elements";

export function ReconciliationModel(i: any): ViewModel {
  return getReconciliationModel(i) as ViewModel;
}

export function ReconciliationQuery(): ViewModelFilter[] {
  return [
    {
      name: "ID",
      key: "id",
      type: "search",
      selected: [],
    },
    {
      name: "Merchant ID",
      key: "merchant_id",
      type: "search",
      selected: [],
    },
    // {
    //   name: "Status",
    //   key: "is_balanced",
    //   type: "options",
    //   options: [
    //     { name: "Balanced", value: "true" },
    //     { name: "Not Balanced", value: "false" },
    //   ],
    //   selected: [],
    // },
    {
      name: "STAN",
      key: "system_trace_audit_number",
      type: "search",
      selected: [],
    },
    {
      name: "TID",
      key: "card_acceptor_terminal_id",
      type: "search",
      selected: [],
    },
  ];
}

const getReconciliationModel = (i: any) => {
  switch (process.env.REACT_APP_DASHBOARD_TYPE) {
    case "accounts":
      return getAccountsReconciliationModel(i);
    case "merchants":
      return getMerchantsReconciliationModel(i);
    case "nsp":
      return getNspReconciliationModel(i);
  }
};

const getAccountsReconciliationModel = (i: any) => {
  let view = {
    State: ReconciliationsStatus(i.is_balanced),
    Amount: ReconciliationAmount(
      Number(Number(Number(i.details.total.total) / 100).toFixed(2)),
      i.currency["english"]
    ),
    Merchant: i.merchant?.name?.["english"],
    Terminal: i.card_acceptor_terminal_id,
    STAN: i.system_trace_audit_number,
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: i.schemes.map((scheme: any) => {
      return {
        Name: scheme.name.label["english"],
        DEBIT: { HOST: scheme.host.debit.total, POS: scheme.pos.debit.total },
        CREDIT: {
          HOST: scheme.host.credit.total,
          POS: scheme.pos.credit.total,
        },
        TOTAL: { HOST: scheme.host.total.total, POS: scheme.pos.total.total },
        COUNT: { HOST: scheme.host.total.count, POS: scheme.pos.total.count },
      };
    }),
    raw: i,
  };
};
const getNspReconciliationModel = (i: any) => {
  let view = {
    State: ReconciliationsStatus(i.is_balanced),
    Amount: ReconciliationAmount(
      Number(Number(Number(i.details.total.total) / 100).toFixed(2)),
      i.currency["english"]
    ),
    Merchant: i.merchant?.name?.["english"],
    Terminal: i.card_acceptor_terminal_id,
    STAN: i.system_trace_audit_number,
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: i.schemes.map((scheme: any) => {
      return {
        Name: scheme.name.label["english"],
        DEBIT: { HOST: scheme.host.debit.total, POS: scheme.pos.debit.total },
        CREDIT: {
          HOST: scheme.host.credit.total,
          POS: scheme.pos.credit.total,
        },
        TOTAL: { HOST: scheme.host.total.total, POS: scheme.pos.total.total },
        COUNT: { HOST: scheme.host.total.count, POS: scheme.pos.total.count },
      };
    }),
    raw: i,
  };
};
const getMerchantsReconciliationModel = (i: any) => {
  let view = {
    State: ReconciliationsStatus(i.is_balanced),
    Amount: ReconciliationAmount(
      Number(Number(Number(i.details.total.total) / 100).toFixed(2)),
      i.currency["english"]
    ),
    Merchant: i.merchant?.name?.["english"],
    Terminal: i.card_acceptor_terminal_id,
    STAN: i.system_trace_audit_number,
    Date: <DateElement date={i.created_at} opt={{ relative: true }} />,
  };
  return {
    list: view,
    details: {
      ...view,
      Date: <DateElement date={i.created_at} opt={{ relative: false }} />,
    },
    relations: i.schemes.map((scheme: any) => {
      return {
        Name: scheme.name.label["english"],
        DEBIT: { HOST: scheme.host.debit.total, POS: scheme.pos.debit.total },
        CREDIT: {
          HOST: scheme.host.credit.total,
          POS: scheme.pos.credit.total,
        },
        TOTAL: { HOST: scheme.host.total.total, POS: scheme.pos.total.total },
        COUNT: { HOST: scheme.host.total.count, POS: scheme.pos.total.count },
      };
    }),
    raw: i,
  };
};
