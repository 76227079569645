import MadaLogo from "./logos/schemes/mada-logo.png";
import VisaLogo from "./logos/schemes/visa.svg";
import MasterCardLogo from "./logos/schemes/mastercard.svg";
import MaestroLogo from "./logos/schemes/maestro.svg";
import AMEXLogo from "./logos/schemes/amex.svg";
import GCCNETLogo from "./logos/schemes/gccnet.jpeg";

export const schemes: Record<
  string,
  { code: string; name: { arabic: string; english: string }; logo: JSX.Element }
> = {
  P1: {
    code: "P1",
    name: {
      arabic: "مدى",
      english: "Mada",
    },
    logo: (
      <img
        className="mix-blend-multiply"
        height={"100%"}
        src={MadaLogo}
        alt="mada logo"
      />
    ),
  },
  VS: {
    code: "VS",
    name: {
      arabic: "فيزا",
      english: "Visa",
    },
    logo: (
      <img
        className="mix-blend-multiply"
        height={"100%"}
        src={VisaLogo}
        alt="Visa logo"
      />
    ),
  },
  VC: {
    code: "VC",
    name: {
      arabic: "فيزا",
      english: "Visa",
    },
    logo: (
      <img
        className="mix-blend-multiply"
        height={"100%"}
        src={VisaLogo}
        alt="Visa logo"
      />
    ),
  },
  MC: {
    code: "MC",
    name: {
      arabic: "مستركارد",
      english: "MasterCard",
    },
    logo: (
      <img
        className="mix-blend-multiply"
        height={"100%"}
        src={MasterCardLogo}
        alt="MasterCard logo"
      />
    ),
  },
  DM: {
    code: "DM",
    name: {
      arabic: "مايسترو",
      english: "Maestro",
    },
    logo: (
      <img
        className="mix-blend-multiply"
        height={"100%"}
        src={MaestroLogo}
        alt="Maestro logo"
      />
    ),
  },
  AX: {
    code: "AX",
    name: {
      arabic: "امريكان اكسبرس",
      english: "American Axpress",
    },
    logo: (
      <img
        className="mix-blend-multiply"
        height={"100%"}
        src={AMEXLogo}
        alt="American Axpress logo"
      />
    ),
  },
  GN: {
    code: "GN",
    name: {
      arabic: "الشبكة الخليجة",
      english: "GCC Payment",
    },
    logo: (
      <img
        className="mix-blend-multiply"
        height={"100%"}
        src={GCCNETLogo}
        alt="GCC Payment logo"
      />
    ),
  },
};
